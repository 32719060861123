import axios from "axios";
// const BackendURL = "http://localhost:8029";
const BackendURL = "https://claim-api.glittr.fi";

export const getUserWalletInfo = async (name: string) => {
    try {
        let config = {
            method: "post",
            url: `${BackendURL}/api/wallet/wallet-info`,
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify({
                walletName: name
            }),
        };

        console.log(config);
        const response = await axios.request(config);
        console.log(response);

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const claimBTC = async (name: string) => {
    try {
        let config = {
            method: "post",
            url: `${BackendURL}/api/claim/claim-btc`,
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify({
                walletName: name
            }),
        };

        console.log(config);
        const response = await axios.request(config);
        console.log(response);

        return response.data;
    } catch (error) {
        console.log(error);
    }
}