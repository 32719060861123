import React, { useEffect, useState } from 'react';
import './App.css';
import { getUserWalletInfo, claimBTC } from './api';
import Logo from "./logo.png";

function App() {

  const [userName, setUserName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [userBalance, setUserBalance] = useState(0);
  const [userAddress, setUserAddress] = useState("");
  const [claimTx, setClaimTx] = useState("");
  const [userRuneBalance, setUserRuneBalance] = useState([]);

  useEffect(() => { }, [userRuneBalance, userName, userBalance, userAddress, errorMsg])

  const getWalletInfo = async () => {
    const res: any = await getUserWalletInfo(userName);
    if (res.success === false) {
      setErrorMsg(res.msg);
      setUserAddress("");
      setUserBalance(0);
      setUserRuneBalance([]);
    } else {
      setErrorMsg("");
      setUserAddress(res.walletinfo.address);
      setUserBalance(res.walletinfo.info.total_amount);
      if (res.walletinfo.runeBalance.length !== 0) {
        const userRunes = res.walletinfo.runeBalance.map((item: any) => {
          return {
            name: item[0],
            balance: " " + item[1] + item[2]
          }
        });
        setUserRuneBalance(userRunes);
      }
    }
  }

  const userClaimBTC = async () => {
    const res: any = await claimBTC(userName);
    console.log(res);

    if (res.success) {
      setClaimTx(`https://explorer.glittr.fi/tx/${res.txId}`);
      const response: any = await getUserWalletInfo(userName);
      if (response.success === true) {
        setUserBalance(response.walletinfo.info.total_amount);
        if (response.walletinfo.runeBalance.length !== 0) {
          const userRunes = response.walletinfo.runeBalance.map((item: any) => {
            return {
              name: item[0],
              balance: " " + item[1] + item[2]
            }
          });
          setUserRuneBalance(userRunes);
        }
      }
    }
  }

  return (
    <>
      <div className='absolute w-full flex items-center h-[100px] px-3'>
        <div className='flex relative w-[300px]'>
          <img src={Logo} className='w-[50px] h-[50px] z-10' alt="Logo" />
          <img src="https://framerusercontent.com/images/AKJjAQJ9pTQYicK5eqmwKscQ.png?scale-down-to=512" className='h-[50px] absolute ml-[30px]' alt="Logo" />
        </div>
      </div>
      <div className="App flex justify-center items-center h-[100vh] bg-black">
        <div>
          {
            userAddress === "" ?
              <>
                <div className="relative flex h-10 w-full min-w-[500px] max-w-[24rem]">
                  <button
                    className="!absolute right-1 top-1 z-10 select-none rounded bg-pink-500 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none peer-placeholder-shown:pointer-events-none peer-placeholder-shown:bg-blue-gray-500 peer-placeholder-shown:opacity-50 peer-placeholder-shown:shadow-none"
                    type="button"
                    data-ripple-light="true"
                    onClick={() => { getWalletInfo() }}
                  >
                    Connect
                  </button>
                  <input
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=" "
                    onChange={(e) => { setUserName(e.target.value) }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 text-white">
                    Input Your Name
                  </label>
                </div>
                {
                  errorMsg !== "" && <p className='text-red-500 mt-1'>{errorMsg}</p>
                }
              </> :
              <>
                <p><span className='text-pink-500 text-xl'>Your Address is : </span> <span className='text-gray-300'>{userAddress}</span></p>
                <p><span className='text-pink-500 text-xl'>Your Balance is : </span> <span className='text-gray-300'>{userBalance} BTC</span></p>
                {
                  userRuneBalance.length !== 0 &&
                  <>
                    <p><span className='text-pink-500 text-xl'>Your Rune Balance is : </span></p>
                    {
                      userRuneBalance.map((item: any, _index) => {
                        return (
                          <div className='flex justify-center' key={_index}>
                            <p className='text-gray-300'>{item.name} : </p>
                            <p className='text-gray-300'>{item.balance}</p>
                          </div>
                        )
                      })
                    }
                  </>
                }
                <button
                  className="right-1 top-1 z-10 rounded bg-pink-500 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none peer-placeholder-shown:pointer-events-none peer-placeholder-shown:bg-blue-gray-500 peer-placeholder-shown:opacity-50 peer-placeholder-shown:shadow-none mt-1"
                  type="button"
                  data-ripple-light="true"
                  onClick={() => { userClaimBTC() }}
                >
                  Claim
                </button>
                <br />
                {
                  claimTx !== "" &&
                  <>
                    <a href={claimTx} target='_blank' rel='noreferrer' className='mt-1 text-blue-600'>{claimTx}</a>
                    <p className='text-gray-300'>Please wait one block for confirm</p>
                  </>
                }
                <button
                  className="right-1 top-1 z-10 rounded bg-pink-500 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none peer-placeholder-shown:pointer-events-none peer-placeholder-shown:bg-blue-gray-500 peer-placeholder-shown:opacity-50 peer-placeholder-shown:shadow-none mt-1"
                  type="button"
                  data-ripple-light="true"
                  onClick={() => {
                    setClaimTx("");
                    setErrorMsg("");
                    setUserAddress("");
                    setUserBalance(0);
                    setUserRuneBalance([]);
                  }}
                >
                  Disconnect
                </button>
              </>
          }
          <div className="w-full pt-2 px-4 mb-8 mx-auto ">
            <div className="text-sm text-gray-700">
              Made with <a className="text-gray-700 font-semibold" href="https://x.com/fcbtcpioneer" rel="noreferrer" target="_blank">Andrew Bay</a> by <a href="https://glittr.fi" className="text-gray-700 font-semibold" rel="noreferrer" target="_blank"> Glittr Team</a>.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
